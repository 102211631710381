import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"

import { FormattedMessage } from "react-intl"

export default function Navigation(props) {
  const {
    settings,
    veryFirstTest,
    questions,
    currentIndex,
    setCurrentIndex,
    userAnswers,
    testStarted,
    settingsIndex,
    setMenuIndex,
  } = props

  function handlePrevious() {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  function handleNext() {
    if (currentIndex < questions.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  function handleToSettings() {
    setMenuIndex(settingsIndex)
  }

  function onClick(e) {
    setCurrentIndex(Number(e.target.id))
  }

  function buttonsNavigation() {
    return (
      <Container component="nav" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Box display="flex">
          <Box display="flex" flexGrow={1}>
            {/* those will be aligned on the left side */}
            <Button variant="contained" color="primary" disabled={currentIndex === 0} onClick={handlePrevious}>
              &#171;
            </Button>
          </Box>

          {/* those will be aligned on the right side */}
          {currentIndex < questions.length - 1 || settings.simulatedTest ? (
            <Button
              variant="contained"
              color="primary"
              disabled={currentIndex === questions.length - 1}
              onClick={handleNext}
            >
              &#187;
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={handleToSettings}>
              <FormattedMessage id="Navigation.toSettings" />
            </Button>
          )}
        </Box>
      </Container>
    )
  }

  function tilesNavigation() {
    return (
      <Container component="nav" disableGutters justify="center" style={{ marginTop: "10px" }}>
        <Grid container justify="center">
          {questions.map((item, index) => {
            const buttonSize = "20px"
            let style = {
              border: "1px solid black",
              borderRadius: "4px",
              margin: "3px",
              height: buttonSize,
              minHeight: buttonSize,
              maxHeight: buttonSize,
              width: buttonSize,
              minWidth: buttonSize,
              maxWidth: buttonSize,
            }

            if (testStarted) {
              // indicate the current question and the questions which are already answered
              if (index === currentIndex) {
                style["backgroundColor"] = "RoyalBlue"
              } else if (userAnswers[index].length > 0) {
                style["backgroundColor"] = "DeepSkyBlue"
              } else {
                style["backgroundColor"] = "LemonChiffon"
              }
            } else {
              // indicate the correctly and incorrectly answered questions
              if (index === currentIndex) {
                style["backgroundColor"] = "SlateGrey"
              } else if (item.correct_answer === userAnswers[index]) {
                style["backgroundColor"] = "Blue"
              } else {
                style["backgroundColor"] = "Red"
              }
            }

            return <Button id={index} key={index} style={style} onClick={onClick}></Button>
          })}
        </Grid>
      </Container>
    )
  }

  return (
    <Container component="nav" style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      {!(settings.simulatedTest && veryFirstTest) && buttonsNavigation()}
      {settings.simulatedTest && !veryFirstTest && tilesNavigation()}
    </Container>
  )
}
