import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import Container from "@material-ui/core/Container"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import CheckIcon from "@material-ui/icons/Check"
import Button from "@material-ui/core/Button"

import { FormattedMessage } from "react-intl"

export default function About(props) {
  const { settingsIndex, setMenuIndex } = props

  function onClose() {
    setMenuIndex(settingsIndex) // show the Settings itself
  }

  return (
    <Container style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      <Typography variant="body1" gutterBottom paragraph>
        <FormattedMessage id="About.part1" />
        <Link
          target="_blank"
          rel="noopener"
          href="https://crc.bg/bg/rubriki/143/testove-za-provejdane-na-izpiti-za-pravosposobnost-na-radiolubiteli"
        >
          <FormattedMessage id="About.CRC" />
        </Link>
      </Typography>
      <Typography variant="body1">
        <FormattedMessage id="About.part2" />
      </Typography>

      <List dense>
        <ListItem>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <FormattedMessage id="About.listItem1" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <FormattedMessage id="About.listItem2" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <FormattedMessage id="About.listItem3" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <FormattedMessage id="About.listItem4" />
        </ListItem>
      </List>

      <Typography variant="body1" paragraph>
        <FormattedMessage id="About.part3" />
      </Typography>

      <Button variant="contained" color="primary" onClick={onClose}>
        <FormattedMessage id="About.continue" />
      </Button>
    </Container>
  )
}
