import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import FormControl from "@material-ui/core/FormControl"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import Divider from "@material-ui/core/Divider"

export default function Question(props) {
  const { settings, question, myIndex, userAnswers, setUserAnswers, testStarted, veryFirstTest } = props

  function handleOnChange(e) {
    setUserAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers]
      newAnswers[myIndex] = e.target.value

      return newAnswers
    })
  }

  const [keyA, keyB, keyV, keyG] = ["А", "Б", "В", "Г"]

  const emptyStyle = {}
  const correctAnswerLabelStyle = { backgroundColor: "DarkSeaGreen" }
  const incorrectAnswerLabelStyle = { backgroundColor: "Tomato" }

  const [styleA, styleB, styleV, styleG] = [keyA, keyB, keyV, keyG].map((item) => {
    if (settings.simulatedTest && testStarted) {
      // don't show any styles in simulated test mode and test running
      return emptyStyle
    } else if (settings.simulatedTest && !testStarted) {
      // show all styles in simulated test mode when reviewing the text results
      if (item === question.correct_answer) {
        return correctAnswerLabelStyle
      } else if (item === userAnswers[myIndex]) {
        return incorrectAnswerLabelStyle
      } else {
        return emptyStyle
      }
    } else {
      // in preparation mode show only the style of the given answer (if any)
      if (item === userAnswers[myIndex]) {
        if (item === question.correct_answer) {
          return correctAnswerLabelStyle
        } else {
          return incorrectAnswerLabelStyle
        }
      } else {
        return emptyStyle
      }
    }
  })

  const radioDisabled = settings.simulatedTest && !testStarted

  const imageQuestion =
    question[keyA].length === 0 &&
    question[keyB].length === 0 &&
    question[keyV].length === 0 &&
    question[keyG].length === 0
  const imageSrc = "image_questions/" + question.question_number + ".png"

  function questionBody() {
    return (
      <>
        <Typography variant="body1" gutterBottom>
          {!settings.simulatedTest ? question.question_number + ". " : ""}
          {question.question_body}
        </Typography>

        {imageQuestion && (
          <>
            <img src={imageSrc} alt="question" width="300" style={{ marginBottom: "15px" }} />
          </>
        )}
      </>
    )
  }

  function possibleAnswers() {
    return (
      <FormControl component="fieldset" style={{ display: "block" }}>
        <RadioGroup>
          <FormControlLabel
            value={keyA}
            control={<Radio size="small" color="primary" style={styleA} />}
            checked={userAnswers[myIndex] === keyA}
            disabled={radioDisabled}
            onChange={handleOnChange}
            label={keyA + ". " + question[keyA]}
          />
        </RadioGroup>

        <RadioGroup>
          <FormControlLabel
            value={keyB}
            control={<Radio size="small" color="primary" style={styleB} />}
            checked={userAnswers[myIndex] === keyB}
            disabled={radioDisabled}
            onChange={handleOnChange}
            label={keyB + ". " + question[keyB]}
          />
        </RadioGroup>

        <RadioGroup>
          <FormControlLabel
            value={keyV}
            control={<Radio size="small" color="primary" style={styleV} />}
            checked={userAnswers[myIndex] === keyV}
            disabled={radioDisabled}
            onChange={handleOnChange}
            label={keyV + ". " + question[keyV]}
          />
        </RadioGroup>

        <RadioGroup>
          <FormControlLabel
            value={keyG}
            control={<Radio size="small" color="primary" style={styleG} />}
            checked={userAnswers[myIndex] === keyG}
            disabled={radioDisabled}
            onChange={handleOnChange}
            label={keyG + ". " + question[keyG]}
          />
        </RadioGroup>
      </FormControl>
    )
  }

  return (
    <Container style={{ marginTop: "20px" }}>
      {(!settings.simulatedTest || !veryFirstTest) && (
        <>
          {questionBody()}
          {possibleAnswers()}
        </>
      )}
      <Divider />
    </Container>
  )
}
