import { useState } from "react"

import Info from "./Info"
import Question from "./Question"
import Navigation from "./Navigation"

export default function QuestionManager(props) {
  const {
    settings,
    questionsMetaData,
    questions,
    currentIndex,
    setCurrentIndex,
    recalculateSelectedQuestions,
    userAnswers,
    setUserAnswers,
    resetUserAnswers,
    settingsIndex,
    setMenuIndex,
  } = props

  // indicates if the user started the simulated test
  const [testStarted, setTestStarted] = useState(() => false)

  // flag that indicates if the test that is about to start is the very first test (after the creation of the QuestinManager component)
  const [veryFirstTest, setVeryFirstTest] = useState(() => true)

  return (
    <>
      <Info
        settings={settings}
        questionsMetaData={questionsMetaData}
        questions={questions}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        userAnswers={userAnswers}
        setUserAnswers={setUserAnswers}
        testStarted={testStarted}
        setTestStarted={setTestStarted}
        veryFirstTest={veryFirstTest}
        setVeryFirstTest={setVeryFirstTest}
        recalculateSelectedQuestions={recalculateSelectedQuestions}
        resetUserAnswers={resetUserAnswers}
      />
      <Question
        settings={settings}
        question={questions[currentIndex]}
        myIndex={currentIndex}
        userAnswers={userAnswers}
        setUserAnswers={setUserAnswers}
        testStarted={testStarted}
        veryFirstTest={veryFirstTest}
      />
      <Navigation
        settings={settings}
        veryFirstTest={veryFirstTest}
        questions={questions}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        userAnswers={userAnswers}
        testStarted={testStarted}
        settingsIndex={settingsIndex}
        setMenuIndex={setMenuIndex}
      />
    </>
  )
}
