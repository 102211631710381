import { useState } from "react"

import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import FormControl from "@material-ui/core/FormControl"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import FormGroup from "@material-ui/core/FormGroup"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"

import { FormattedMessage, defineMessages, useIntl } from "react-intl"

export default function Settings(props) {
  const {
    setMenuIndex,
    settings,
    setSettings,
    questionsMetaData,
    setCurrentIndex,
    setUserAnswers,
    resetUserAnswers,
    recalculateSelectedQuestions,
  } = props

  const [alertOpen, setAlertOpen] = useState(false)

  const handleClose = () => {
    setAlertOpen(false)
  }

  const messages = defineMessages({
    preparation: {
      id: "Settings.preparation",
    },
    simulatedTest: {
      id: "Settings.simulatedTest",
    },
    consecutiveQuestions: {
      id: "Settings.consecutiveQuestions",
    },
    shuffleQuestions: {
      id: "Settings.shuffleQuestions",
    },
    save: {
      id: "Settings.save",
    },
  })
  const intl = useIntl()
  const preparationLabel = intl.formatMessage(messages.preparation)
  const simulatedTestLabel = intl.formatMessage(messages.simulatedTest)
  const consecutiveQuestionsLabel = intl.formatMessage(messages.consecutiveQuestions)
  const shuffleQuestionsLabel = intl.formatMessage(messages.shuffleQuestions)
  const saveLabel = intl.formatMessage(messages.save)

  function classChanged(e) {
    setSettings((prev) => {
      let prevCopy = { ...prev }
      prevCopy.selectedClass = e.target.value
      prevCopy.selectedSections = questionsMetaData[e.target.value]

      return prevCopy
    })
  }

  function modeChanged(e) {
    setSettings((prev) => {
      let prevCopy = { ...prev }
      if (e.target.value === "test") {
        prevCopy.simulatedTest = true
      } else {
        prevCopy.simulatedTest = false
      }

      return prevCopy
    })
  }

  function orderChanged(e) {
    setSettings((prev) => {
      let prevCopy = { ...prev }
      if (e.target.value === "shuffle") {
        prevCopy.shuffleQuestions = true
      } else {
        prevCopy.shuffleQuestions = false
      }

      return prevCopy
    })
  }

  function sectionChanged(e) {
    setSettings((prev) => {
      const seceltedSectionsCopy = [...prev.selectedSections]

      if (e.target.checked) {
        seceltedSectionsCopy.push(e.target.value)
        seceltedSectionsCopy.sort() // not actually needed but it's nicer when the array is sorted
      } else {
        const indexToRemove = prev.selectedSections.indexOf(e.target.value)
        seceltedSectionsCopy.splice(indexToRemove, 1)
      }

      const prevCopy = { ...prev }
      prevCopy.selectedSections = seceltedSectionsCopy

      return prevCopy
    })
  }

  function onSave() {
    if (!settings.simulatedTest && settings.selectedSections.length === 0) {
      setAlertOpen(true)
    } else {
      recalculateSelectedQuestions() // recalculate the selected questions based on the settings
      setCurrentIndex(0) // reset the question index
      setUserAnswers(resetUserAnswers()) // clear all user answers and start over
      setMenuIndex(0) // show the app itself
    }
  }

  return (
    <>
      <Container style={{ paddingBottom: "15px" }}>
        <Typography variant="h6" style={{ marginTop: "15px", marginBottom: "15px" }}>
          <FormattedMessage id="Settings.title" />
        </Typography>
        <Divider />

        <FormControl component="fieldset" style={{ display: "block" }}>
          <RadioGroup>
            {Object.keys(questionsMetaData).map((key, arr) => {
              const index = key.slice(-1)
              const checked = settings.selectedClass === key

              return (
                <FormControlLabel
                  key={index}
                  value={key}
                  control={<Radio size="small" color="primary" />}
                  checked={checked}
                  onChange={classChanged}
                  label={key}
                />
              )
            })}
          </RadioGroup>
          <Divider />

          <RadioGroup>
            <FormControlLabel
              value="prepare"
              control={<Radio size="small" color="primary" />}
              checked={!settings.simulatedTest}
              onChange={modeChanged}
              label={preparationLabel}
            />
            <FormControlLabel
              value="test"
              control={<Radio size="small" color="primary" />}
              checked={settings.simulatedTest}
              onChange={modeChanged}
              label={simulatedTestLabel}
            />
          </RadioGroup>
          <Divider />

          {!settings.simulatedTest && (
            <>
              <RadioGroup>
                <FormControlLabel
                  value="consequtive"
                  control={<Radio size="small" color="primary" />}
                  checked={!settings.shuffleQuestions}
                  onChange={orderChanged}
                  label={consecutiveQuestionsLabel}
                />
                <FormControlLabel
                  value="shuffle"
                  control={<Radio size="small" color="primary" />}
                  checked={settings.shuffleQuestions}
                  onChange={orderChanged}
                  label={shuffleQuestionsLabel}
                />
              </RadioGroup>
              <Divider />

              <FormGroup>
                {questionsMetaData[settings.selectedClass].map((item, index) => {
                  const checked = settings.selectedSections.includes(item)

                  return (
                    <FormControlLabel
                      key={index}
                      value={item}
                      control={<Checkbox size="small" color="primary" />}
                      checked={checked}
                      onChange={sectionChanged}
                      label={item}
                    />
                  )
                })}
              </FormGroup>
              <Divider />
            </>
          )}
        </FormControl>

        <Button variant="contained" color="primary" style={{ marginTop: "15px" }} onClick={onSave}>
          {saveLabel}
        </Button>
      </Container>

      <Dialog open={alertOpen} onClose={handleClose}>
        <DialogTitle>
          <FormattedMessage id="Settings.chooseAtLeastOneSection" />
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <FormattedMessage id="Settings.alertButtonText" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
