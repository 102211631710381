import { useState } from "react"

import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"

import { FormattedMessage } from "react-intl"

export default function ApplicationBar(props) {
  const { english, setEnglish, menuIndex, settingsIndex, setMenuIndex } = props

  const [anchorEl, setAnchorEl] = useState(null)

  function onMenuClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function onMenuClose() {
    setAnchorEl(null)
  }

  function onItemClick(event) {
    setMenuIndex(event.target.value)
    onMenuClose()
  }

  function onEnglishClicked() {
    setEnglish(!english)
    onMenuClose()
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <Box display="flex" flexGrow={1}>
          {/* those will be aligned on the left side */}
          <Typography variant="h5">
            <FormattedMessage id="ApplicationBar.title" />
          </Typography>
        </Box>
        {/* those will be aligned on the right side */}
        <IconButton
          edge="start"
          color="secondary"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={onMenuClick}
        >
          <MenuIcon />
        </IconButton>

        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onMenuClose}>
          {
            // TODO: the number 3 should come from languages.json and not be hard-coded
            Array.from({ length: 3 }, (v, k) => k).map((index) => {
              const langId = "ApplicationBar.menu" + index

              // all menu item are disabled while in Setting page
              return (
                <MenuItem
                  key={index}
                  value={index}
                  disabled={menuIndex === index || menuIndex === settingsIndex}
                  onClick={onItemClick}
                >
                  <FormattedMessage id={langId} />
                </MenuItem>
              )
            })
          }

          <MenuItem>
            <FormControlLabel
              style={{ margin: "0" }}
              checked={english}
              control={<Switch color="primary" />}
              label="English (UI only)"
              labelPlacement="start"
              onChange={onEnglishClicked}
            />
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}
