import { useState, useEffect, useRef, useCallback } from "react"

import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Box from "@material-ui/core/Box"
import LinearProgress from "@material-ui/core/LinearProgress"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import { FormattedMessage, defineMessages, useIntl } from "react-intl"

export default function Info(props) {
  const {
    settings,
    questionsMetaData,
    questions,
    currentIndex,
    setCurrentIndex,
    userAnswers,
    setUserAnswers,
    testStarted,
    setTestStarted,
    veryFirstTest,
    setVeryFirstTest,
    recalculateSelectedQuestions,
    resetUserAnswers,
  } = props

  const selectedClass = settings.selectedClass // the class selected by the user
  const section = findSection(currentIndex) // find the section for the question at currentIndex

  const testTime = 40 * 60 // number of seconds in 40 minutes
  const [remainingTime, setRemainingTime] = useState(() => {
    return testTime // seconds in one hour
  })

  let setIntervalId = useRef(null)

  const messages = defineMessages({
    progress: {
      id: "Info.progress",
    },
    testSuccessTitle: {
      id: "Info.testSuccessTitle",
    },
    testSuccess: {
      id: "Info.testSuccess",
    },
    testFailureTitle: {
      id: "Info.testFailureTitle",
    },
    testFailure: {
      id: "Info.testFailure",
    },
  })
  const intl = useIntl()
  const progressLabel = intl
    .formatMessage(messages.progress)
    .replace("QUESTIONS", currentIndex + 1)
    .replace("QUESTIONS_ALL", questions.length)

  function findSection(index) {
    const sectionIndex = questions[index].section_index
    const section = questionsMetaData[selectedClass][sectionIndex]

    return section
  }

  function onRefresh() {
    recalculateSelectedQuestions() // re-shuffle the questions

    setUserAnswers(resetUserAnswers()) // reset the user answers to the default values

    setCurrentIndex(0) // reset the current index
  }

  function onStart() {
    onRefresh()

    setRemainingTime(testTime)

    setIntervalId.current = setInterval(() => {
      setRemainingTime((prev) => {
        return prev - 1
      })
    }, 1000)

    setTestStarted((prev) => !prev)
    setVeryFirstTest(false)
  }

  const [openTestResults, setOpenTestResults] = useState(false)
  const handleClose = () => {
    setOpenTestResults(false)
  }
  const [testResultTitle, setTestResultTitle] = useState("")
  const [testResultText, setTestResultText] = useState("")
  const onSubmit = useCallback(() => {
    if (setIntervalId) {
      clearInterval(setIntervalId.current)

      let correctAnswers = 0
      for (let i = 0; i < userAnswers.length; ++i) {
        if (userAnswers[i] === questions[i].correct_answer) {
          correctAnswers += 1
        }
      }

      const testSuccess = correctAnswers >= 48

      setTestResultTitle(() => {
        const newTitle = testSuccess
          ? intl.formatMessage(messages.testSuccessTitle)
          : intl.formatMessage(messages.testFailureTitle)

        return newTitle
      })

      setTestResultText(() => {
        const newText = (testSuccess
          ? intl.formatMessage(messages.testSuccess)
          : intl.formatMessage(messages.testFailure)
        )
          .replace("CORRECT_ANSWERS", correctAnswers)
          .replace("QUESTIONS", questions.length)

        return newText
      })

      setOpenTestResults(true)
    }
    setTestStarted((prev) => !prev)
  }, [questions, setTestStarted, userAnswers, intl, messages])

  useEffect(() => {
    return () => {
      // onUnmount
      if (setIntervalId) {
        clearInterval(setIntervalId.current)
      }
    }
  }, [])

  const minutes = Math.floor(remainingTime / 60)
    .toString()
    .padStart(2, 0)
  const seconds = (remainingTime % 60).toString().padStart(2, 0)

  useEffect(() => {
    if (remainingTime <= 0) {
      onSubmit()
    }
  }, [remainingTime, onSubmit])

  function preparationMode() {
    return (
      <>
        <Typography variant="body1" gutterBottom>
          {selectedClass}
        </Typography>

        <Typography variant="body1" gutterBottom>
          {section}
        </Typography>

        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" value={((currentIndex + 1) / questions.length) * 100} />
          </Box>
          <Box minWidth={80}>
            <Typography variant="body2" color="textSecondary">
              {progressLabel}
            </Typography>
          </Box>
        </Box>
      </>
    )
  }

  function simulatedTestMode() {
    return (
      <>
        <Box display="flex">
          <Box display="flex" flexGrow={1}>
            {/* those will be aligned on the left side */}
            <Typography variant="h6" display="inline" style={{ border: "1px solid black", padding: "4px" }}>
              {minutes}:{seconds}
            </Typography>
          </Box>

          {/* those will be aligned on the right side */}
          {!testStarted ? (
            <Button variant="contained" color="primary" onClick={onStart}>
              {veryFirstTest ? (
                <FormattedMessage id="Info.startButton1" />
              ) : (
                <FormattedMessage id="Info.startButton2" />
              )}
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={onSubmit}>
              <FormattedMessage id="Info.submit" />
            </Button>
          )}
        </Box>
      </>
    )
  }

  return (
    <>
      <Container style={{ marginTop: "20px" }}>
        {!settings.simulatedTest ? preparationMode() : simulatedTestMode()}
        <Divider style={{ marginTop: "15px" }} />
      </Container>

      <Dialog open={openTestResults} onClose={handleClose}>
        <DialogTitle>{testResultTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{testResultText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            <FormattedMessage id="Info.testResultButton" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
