import "./App.css"

import { useState } from "react"

import { IntlProvider } from "react-intl"
import localeData from "./locales/languages.json"

import ApplicationBar from "./ApplicationBar"
import Settings from "./Settings"
import About from "./About"
import QuestionManager from "./QuestionManager"

import Grid from "@material-ui/core/Grid"

// the questions and the coresponding meta data loaded from the input .json files
const { questions, questionsMetaData } = loadQuestions()

export default function App() {
  const [menuIndex, setMenuIndex] = useState(2)

  // defines the language: the default language is BG and EN is just for demo purpose
  const [english, setEnglish] = useState(false)
  const languageWithoutRegionCode = english ? "en" : "bg"
  const messages = localeData[languageWithoutRegionCode]

  // user specified setting are kept here
  const [settings, setSettings] = useState(() => {
    const initialClass = Object.keys(questionsMetaData).pop()
    const initialSections = questionsMetaData[initialClass]
    return {
      selectedClass: initialClass,
      simulatedTest: false,
      shuffleQuestions: false,
      selectedSections: initialSections,
    }
  })

  // array of questions (subset of the question from the input .json files) based on the user specified settings
  const [selectedQuestions, setSelectedQuestions] = useState(() => {
    return questions[Object.keys(questionsMetaData).pop()]
  })

  // keeps the index of the currently displayed question (from the array of questions)
  const [currentIndex, setCurrentIndex] = useState(() => 0)

  // array of empty strings with the same length as the questions array
  // the answers provided by the user are stored here
  const [userAnswers, setUserAnswers] = useState(resetUserAnswers())

  function recalculateSelectedQuestions() {
    const selectedClass = settings.selectedClass
    let result = [...questions[selectedClass]] // pick up the questions for the selected class and don't forget to clone the array

    if (!settings.simulatedTest) {
      // preparation for a test
      // filter the sections
      result = result.filter((item) => {
        const sectionName = questionsMetaData[selectedClass][item.section_index]
        return settings.selectedSections.includes(sectionName)
      })
      // shuffle the questions if requested
      if (settings.shuffleQuestions) {
        result.sort(() => Math.random() - 0.5)
      }
    } else {
      // simulated test
      // in case of simulated test always shuffle the questions and return only the first 60 of them
      result.sort(() => Math.random() - 0.5)
      result.length = 60 // TODO: check if the time for the test is actually 60 minutes
    }

    setSelectedQuestions(result)
  }

  function resetUserAnswers() {
    return Array.apply(null, Array(selectedQuestions.length)).map(() => {
      return ""
    })
  }

  return (
    <IntlProvider locale={languageWithoutRegionCode} messages={messages}>
      <Grid container direction="column">
        <ApplicationBar
          english={english}
          setEnglish={setEnglish}
          menuIndex={menuIndex}
          settingsIndex={1}
          setMenuIndex={setMenuIndex}
        />

        <Grid item container>
          {/* the left empty column/s */}
          <Grid item xs={false} md={1} lg={2} xl={3} />

          <Grid item xs={12} md={10} lg={8} xl={6}>
            {menuIndex === 1 && (
              <Settings
                setMenuIndex={setMenuIndex}
                settings={settings}
                setSettings={setSettings}
                questionsMetaData={questionsMetaData}
                setCurrentIndex={setCurrentIndex}
                setUserAnswers={setUserAnswers}
                resetUserAnswers={resetUserAnswers}
                recalculateSelectedQuestions={recalculateSelectedQuestions}
              />
            )}
            {menuIndex === 2 && <About settingsIndex={1} setMenuIndex={setMenuIndex} />}
            {menuIndex === 0 && (
              <QuestionManager
                settings={settings}
                questionsMetaData={questionsMetaData}
                questions={selectedQuestions}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                recalculateSelectedQuestions={recalculateSelectedQuestions}
                userAnswers={userAnswers}
                setUserAnswers={setUserAnswers}
                resetUserAnswers={resetUserAnswers}
                settingsIndex={1}
                setMenuIndex={setMenuIndex}
              />
            )}
          </Grid>

          {/* the right empty column/s */}
          <Grid item xs={false} md={1} lg={2} xl={3} />
        </Grid>

        {/*
        hide the Footer becaose cannot place it at the bottom
        <Footer />
      */}
      </Grid>
    </IntlProvider>
  )
}

function loadQuestions() {
  // load the question data from json files
  const questionData = [
    require("./data/Class1_Section1.json"),
    require("./data/Class1_Section2.json"),
    require("./data/Class1_Section3.json"),
    require("./data/Class2_Section1.json"),
    require("./data/Class2_Section2.json"),
    require("./data/Class2_Section3.json"),
  ]

  // form the metadata
  let questionsMetaData = {}

  for (let i = 0; i < questionData.length; ++i) {
    const radioClass = questionData[i].class // expected to exist

    if (!(radioClass in questionsMetaData)) {
      questionsMetaData[radioClass] = []
    }

    questionsMetaData[radioClass].push(questionData[i].section)
  }

  // form the questions in the appropriate format
  let questions = []

  for (let i = 0; i < questionData.length; ++i) {
    for (let j = 0; j < questionData[i].questions.length; ++j) {
      const radioClass = questionData[i].class
      const section = questionData[i].section
      const sectionIndex = questionsMetaData[radioClass].indexOf(section)

      let item = questionData[i].questions[j]
      item["section_index"] = sectionIndex

      if (!(radioClass in questions)) {
        questions[radioClass] = []
      }

      questions[radioClass].push(item)
    }
  }

  return { questions, questionsMetaData }
}
